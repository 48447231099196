// CustomRadialScale.js
import { RadialLinearScale, Chart } from 'chart.js';

// Create the custom radial scale
class CustomRadialScale extends RadialLinearScale {
    determineDataLimits() {
        super.determineDataLimits();
        // Set the scale limits
        this.min = 0;
        this.max = 100;
    }

    getDistanceFromCenterForValue(value) {
        const maxDistance = this.drawingArea;
        const minDistance = 0;
        const valuePercentage = (value - this.min) / (this.max - this.min);

        let adjustedPercentage;
        const proportion = 0.35; // The range 0-20 occupies 35% of the radius

        if (valuePercentage <= 0.2) {
        adjustedPercentage = (valuePercentage / 0.2) * proportion;
        } else {
        adjustedPercentage =
            proportion + ((valuePercentage - 0.2) / 0.8) * (1 - proportion);
        }

        return minDistance + (maxDistance - minDistance) * adjustedPercentage;
    }

    getValueForDistanceFromCenter(distance) {
        const maxDistance = this.drawingArea;
        const minDistance = 0;
        const adjustedPercentage =
        (distance - minDistance) / (maxDistance - minDistance);

        let valuePercentage;
        const proportion = 0.35;

        if (adjustedPercentage <= proportion) {
        valuePercentage = (adjustedPercentage / proportion) * 0.2;
        } else {
        valuePercentage =
            0.2 + ((adjustedPercentage - proportion) / (1 - proportion)) * 0.8;
        }

        return this.min + valuePercentage * (this.max - this.min);
    }
}

// Assign a unique ID to the custom scale
CustomRadialScale.id = 'customRadial';
// Set default options
CustomRadialScale.defaults = RadialLinearScale.defaults;

// Register the custom scale
Chart.register(CustomRadialScale);

// Export the scale
export default CustomRadialScale;
