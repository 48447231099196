import React, { useState, useEffect } from 'react';
import BalanceRadarChart from './balanceRadarChart';
import {
    useTheme,
    Box,
    Typography,
    Divider,
    Grid,
    useMediaQuery,
    } from '@mui/material';
import buildingsBackground from '../../assets/images/fondo-edificiospng.png';
import { publicAxios } from '../../apiClients';
import BackButton from '../../components/BackButton';
import SelectOptionMM from '../../components/SelectOptionMM';

// Función auxiliar para convertir hex a rgba
const hexToRgba = (hex, alpha) => {
const hexValue = hex.replace('#', '');
const r = parseInt(hexValue.substring(0, 2), 16);
const g = parseInt(hexValue.substring(2, 4), 16);
const b = parseInt(hexValue.substring(4, 6), 16);

return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const MarketComparative = () => {
    const theme = useTheme();

    // Uso de useMediaQuery para detectar pantallas pequeñas
    const isMobile = useMediaQuery('(max-width:600px)');

    // Estado para la segunda firma seleccionada (sin incluir Firma 1)
    const [selectedFirma, setSelectedFirma] = useState('');
    const [firm, setFirm] = useState(null);
    const [otherFirms, setOtherFirms] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const firmId = localStorage.getItem('firmId');
        const fetchFirm = async () => {
        try {
            const response = await publicAxios.get(
            `/institution/market-monitor/${firmId}`
            );
            setFirm(response.data);
        } catch (error) {
            console.error('Error fetching firm data:', error);
        }
        };
        fetchFirm();
    }, []);

    useEffect(() => {
        const fetchOtherFirm = async () => {
        try {
            const response = await publicAxios.get(
            `/institution/market-monitor/comparative`
            );
            setOtherFirms(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching firm data:', error);
        }
        };
        fetchOtherFirm();
    }, []);

    const firmMetrics = [
        firm?.size,
        firm?.revenue,
        firm?.trajectory,
        firm?.arena,
        firm?.individualPerformance,
        firm?.reputation,
        firm?.clients,
        firm?.totalScore,
    ];

    // Crear una estructura con nombres y métricas
    const firmas = {
        ...otherFirms
        .sort((a, b) => a.institutionName.localeCompare(b.institutionName))
        .reduce((acc, f) => {
            acc[f.institutionName] = {
            institutionName: f.institutionName,
            totalScore: f.totalScore,
            country: f.Country.name,
            metrics: [
                f.size,
                f.revenue,
                f.trajectory,
                f.arena,
                f.individualPerformance,
                f.reputation,
                f.clients,
                f.totalScore,
            ],
            };
            return acc;
        }, {}),
    };

    const primaryColor = '#F67147';
    const secondaryColor = '#47A6F6'; // Color para la segunda firma

    // Mapa de colores para las firmas
    const firmColors = {
        'Firma 1': primaryColor,
        'Firma 2': secondaryColor,
        // Agrega más firmas y sus colores según sea necesario
    };

    // Generar datasets para "Firma 1" y la firma adicional seleccionada
    const datasets = [
        {
        label: 'Firma 1',
        data: firmMetrics,
        fill: true,
        borderColor: firmColors['Firma 1'] || '#888888',
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: (ctx) => {
            const { chartArea, ctx: context } = ctx.chart;
            if (!chartArea) return;

            const gradient = context.createRadialGradient(
            chartArea.width / 2,
            chartArea.height / 2,
            0,
            chartArea.width / 2,
            chartArea.height / 2,
            chartArea.width / 2
            );

            const colorStart = hexToRgba(firmColors['Firma 1'], 0.2);
            const colorMid = hexToRgba(firmColors['Firma 1'], 0.5);
            const colorEnd = hexToRgba(firmColors['Firma 1'], 0.8);

            gradient.addColorStop(0, colorStart);
            gradient.addColorStop(0.5, colorMid);
            gradient.addColorStop(1, colorEnd);

            return gradient;
        },
        },
    ];

    if (selectedFirma && firmas[selectedFirma]) {
        datasets.push({
        label: selectedFirma,
        data: firmas[selectedFirma]?.metrics,
        fill: true,
        borderColor: firmColors[selectedFirma] || secondaryColor,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: (ctx) => {
            const { chartArea, ctx: context } = ctx.chart;
            if (!chartArea) return;

            const gradient = context.createRadialGradient(
            chartArea.width / 2,
            chartArea.height / 2,
            0,
            chartArea.width / 2,
            chartArea.height / 2,
            chartArea.width / 2
            );

            const colorStart = hexToRgba(
            firmColors[selectedFirma] || secondaryColor,
            0.2
            );
            const colorMid = hexToRgba(
            firmColors[selectedFirma] || secondaryColor,
            0.5
            );
            const colorEnd = hexToRgba(
            firmColors[selectedFirma] || secondaryColor,
            0.8
            );

            gradient.addColorStop(0, colorStart);
            gradient.addColorStop(0.5, colorMid);
            gradient.addColorStop(1, colorEnd);

            return gradient;
        },
        });
    }

    const data = {
        labels: [
        'Size',
        'Revenue',
        'Trajectory',
        'Arena',
        'Individual Performance',
        'Reputation',
        'Clients',
        'Total',
        ],
        datasets, // Usar el array de datasets actualizado
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
        r: {
            type: 'customRadial',
            min: 0,
            max: 100,
            ticks: {
            min: 0,
            beginAtZero: true,
            stepSize: 20,
            showLabelBackdrop: false,
            color: theme.palette.gray4,
            padding: 10,
            labelOffset: 5,
            font: {
                size: 0,
                family: theme.typography.fontFamily,
                lineHeight: 1.5,
            },
            },
            angleLines: {
            display: true,
            color: theme.palette.gray4,
            },
            grid: {
            color: theme.palette.gray4,
            },
            pointLabels: {
            color: 'white',
            font: {
                size: isMobile ? 9 : 13,
                family: theme.typography.fontFamily,
            },
            callback: function (label) {
                if (label === 'Individual Performance') {
                return ['Individual', 'Performance'];
                }
                return label;
            },
            },
        },
        },
        plugins: {
        legend: {
            display: false, // No mostrar leyenda
        },
        tooltip: {
            backgroundColor: theme.palette.common.white,
            titleColor: 'white',
            bodyColor: 'white',
            borderWidth: 1,
            borderColor: 'white',
            titleFont: {
            size: 12,
            family: theme.typography.fontFamily,
            },
            bodyFont: {
            size: 12,
            family: theme.typography.fontFamily,
            },
        },
        },
    };

    return (
        <Box
        sx={{
            backgroundImage: `url(${buildingsBackground})`,
            backgroundSize: 'cover',
            minHeight: isMobile ? '100vh' : 'auto', // Cambiado de 'height' a 'minHeight'
            height: isMobile ? 'auto' : '120vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pb: 27,
            pt: 1,
        }}
        >
        <Grid container justifyContent="start" sx={{ mt: 0, ml: 1, mb: 0 }}>
            <BackButton path={-1} />
        </Grid>
        {/* Select sobre el Box horizontal */}
        <Box sx={{ width: isMobile ? '80%' : '20%', mb: 5 }}>
            <SelectOptionMM
            options={Object.keys(firmas).map((key) => ({ id: key, name: key }))}
            onSelectionChange={setSelectedFirma}
            name="Choose a firm"
            width="100%"
            selectedOption={selectedFirma}
            />
        </Box>

        {/* Caja azul transparente */}
        <Box
            sx={{
            backgroundColor: 'rgba(3, 38, 61, 0.8)',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 14,
            ...(isMobile && {
                flexDirection: 'column',
                px: 2,
                py: 4,
            }),
            }}
        >
            {/* Divider izquierdo */}
            {!isMobile && (
            <Divider
                orientation="vertical"
                flexItem
                sx={{
                height: '121%',
                borderColor: 'white',
                borderWidth: '1px',
                transform: 'translateY(-7%)',
                }}
            />
            )}

            {/* Puntaje izquierdo */}
            <Box
            sx={{
                textAlign: 'left',
                width: isMobile ? '100%' : '20%',
                marginLeft: '10px',
                marginTop: isMobile ? '28px' : 0,
                ...(isMobile && { order: 2 }),
            }}
            >
            <Box sx={{ display: 'flex-start', alignItems: 'center' }}>
                <Box
                sx={{
                    width: '22px',
                    height: '22px',
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '50%',
                    marginRight: '10%',
                    marginBottom: '7%',
                }}
                />
                <Typography variant="h5" color="white">
                {firm?.institutionName}
                </Typography>
            </Box>
            <Typography fontSize={17} color={theme.palette.gray3}>
                {firm?.Country.name}
            </Typography>
            <Typography variant="h3" color={theme.palette.primary.main}>
                {firm?.totalScore || 'N/A'}
            </Typography>
            <Typography color="white">Total Score</Typography>
            </Box>

            {/* Gráfico en el centro */}
            <Box
            sx={{
                position: 'relative',
                borderRadius: '16px',
                paddingY: isMobile ? '0px' : '2%',
                height: isMobile ? '100%' : '95%',
                width: isMobile ? '100%' : '55%',
                ...(isMobile && {
                order: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '300px', // Establecer una altura mínima menor en pantallas pequeñas
                }),
                ...(!isMobile && {
                minHeight: '500px',
                }),
            }}
            >
                <BalanceRadarChart data={data} options={options} />
            </Box>

            {/* Puntaje derecho */}
            <Box
            sx={{
                textAlign: 'right',
                width: isMobile ? '100%' : '20%',
                marginRight: '10px',
                marginTop: isMobile ? '2%' : 0,
                marginBottom: isMobile ? '15%' : 0,
                ...(isMobile && { order: 4 }),
            }}
            >
            <Box
                sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                }}
            >
                <Box
                sx={{
                    width: '22px',
                    height: '22px',
                    backgroundColor: '#3592D2',
                    borderRadius: '50%',
                    marginBottom: '7%',
                    marginLeft: isMobile ? '95%' : '91%',
                }}
                />
                <Typography variant="h5" color="white">
                {firmas[selectedFirma]?.institutionName}
                </Typography>
                <Typography fontSize={17} color={theme.palette.gray3}>
                    {firmas[selectedFirma]?.country}
                </Typography>
                <Typography variant="h3" color="#3592D2">
                {selectedFirma
                    ? firmas[selectedFirma]?.totalScore
                    ? firmas[selectedFirma].totalScore
                    : 'N/A'
                    : ''}
                </Typography>
                <Typography color="white">Total Score</Typography>
            </Box>
            </Box>

            {/* Divider derecho */}
            {!isMobile && (
            <Divider
                orientation="vertical"
                flexItem
                sx={{
                height: '121%',
                borderColor: 'white',
                borderWidth: '1px',
                transform: 'translateY(-7%)',
                }}
            />
            )}
        </Box>
        </Box>
    );
};

export default MarketComparative;
