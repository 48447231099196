// RadarChart.js
import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTheme, useMediaQuery } from '@mui/material';

// Import the custom radial scale
import CustomRadialScale from './customRadialScale';

// Register necessary components (excluding RadialLinearScale as it's registered in CustomRadialScale)
Chart.register(PointElement, LineElement, Filler, Tooltip, Legend);

// Helper function to convert hex to rgba
const hexToRgba = (hex, alpha) => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const RadarChart = ({firm}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dataValues = [firm?.size, firm?.revenue, firm?.trajectory, firm?.arena, firm?.individualPerformance, firm?.reputation, firm?.clients, firm?.totalScore];

  const primaryColor = '#F67147';

  const data = {
    labels: [
      'Size',
      'Revenue',
      'Trajectory',
      'Arena',
      'Individual Performance',
      'Reputation',
      'Clients',
      'Total',
    ],
    datasets: [
      {
        data: dataValues,
        fill: false, // Disable default fill
        borderColor: primaryColor,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      r: {
        type: 'customRadial', // Use the shared custom scale
        min: 0,
        max: 100,
        ticks: {
          min: 0,
          beginAtZero: true,
          stepSize: 20,
          showLabelBackdrop: false,
          color: theme.palette.gray4,
          padding: 10, // Adjust to move labels outward
          labelOffset: 5, // Adjust to shift labels radially
          font: {
            size: 0,
            family: theme.typography.fontFamily,
            lineHeight: 1.5,
          },
        },
        angleLines: {
          display: true,
          color: theme.palette.gray4,
        },
        grid: {
          color: theme.palette.gray3,
        },
        pointLabels: {
          color: 'white',
          font: {
            size: isMobile ? 9 : 13,
            family: theme.typography.fontFamily,
          },
          callback: function (label) {
            // Split 'Individual Performance' into two lines
            if (label === 'Individual Performance') {
              return ['Individual', 'Performance'];
            }
            return label;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: theme.palette.common.white,
        titleColor: theme.palette.gray1,
        bodyColor: theme.palette.gray2,
        borderWidth: 1,
        borderColor: theme.palette.gray4,
        titleFont: {
          size: 12,
          family: theme.typography.fontFamily,
        },
        bodyFont: {
          size: 12,
          family: theme.typography.fontFamily,
        },
      },
    },
  };

  // Create the custom gradient fill plugin
  const gradientFillPlugin = {
    id: 'gradientFillPlugin',
    beforeDatasetsDraw: (chart) => {
      const { ctx, chartArea } = chart;

      if (!chartArea) {
        // Happens on initial load
        return;
      }

      const dataset = chart.data.datasets[0];
      const meta = chart.getDatasetMeta(0);

      ctx.save();

      // Create radial gradient with transparency
      const gradient = ctx.createRadialGradient(
        chartArea.width / 2,
        chartArea.height / 2,
        0,
        chartArea.width / 2,
        chartArea.height / 2,
        chartArea.width / 2
      );

      // Define RGBA colors with transparency using the primary color
      const colorStart = hexToRgba(primaryColor, 0.2); // 20% opacity
      const colorMid = hexToRgba(primaryColor, 0.7);   // 70% opacity
      const colorEnd = hexToRgba(primaryColor, 0.9);   // 90% opacity

      // Define color stops for the gradient
      gradient.addColorStop(0, colorStart);
      gradient.addColorStop(0.5, colorMid);
      gradient.addColorStop(1, colorEnd);

      // Set fill style
      ctx.fillStyle = gradient;

      // Draw the radar area
      ctx.beginPath();
      const points = meta.data;
      ctx.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
      }

      ctx.closePath();
      ctx.fill();

      ctx.restore();
    },
  };

  // Pass the plugin to the chart instance (do not register globally)
  return <Radar data={data} options={options} plugins={[gradientFillPlugin]} />;
};

export default RadarChart;
