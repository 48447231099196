import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Box, Container, Button, Divider } from '@mui/material';
import name from '../assets/logo/cropped-LIR.png';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function ResponsiveAppBar() {
    const navigate = useNavigate();
    const location = useLocation();

    const showAllFirmsButton =
        location.pathname !== '/' && location.pathname !== '/market-monitor';

    return (
        <AppBar
        position="static"
        sx={{
            backgroundColor: 'white',
            minHeight: '13vh',
            display: 'flex',
            justifyContent: 'center',
        }}
        >
        <Container
            maxWidth="100%"
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
            {/* Contenedor principal para centrar los elementos */}
            <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Centra horizontalmente
                width: '100%',
            }}
            >
            {/* Contenedor interno para los elementos */}
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                }}
            >
                <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecoration: 'none',
                }}
                >
                    <Box
                        component="img"
                        src={name}
                        sx={{
                        maxWidth: showAllFirmsButton ? '6.5%' : '7.25%',
                        objectFit: 'contain',
                        marginRight: '0.5rem',
                        }}
                    />
                        <Button
                            component="a"
                            href="https://thelegalindustry.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textTransform: 'none',
                                fontSize: '1rem',
                                color: '#808080',
                                '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#333',
                                },
                            }}
                            >
                            LIR Home Page
                        </Button>
                        {/* Botón "All Firms" con visibilidad corregida y centrado */}
                        {showAllFirmsButton && (
                        <Button
                            onClick={() => navigate('/market-monitor')}
                            sx={{
                            textTransform: 'none',
                            fontSize: '1rem',
                            marginLeft: '1%',
                            color: 'gray',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#333',
                            },
                            }}
                        >
                            All firms
                            <TrendingUpIcon
                            sx={{
                                fontSize: '1.5rem',
                                marginLeft: '0.5rem',
                                color: 'rgb(229, 60, 97)',
                            }}
                            />
                        </Button>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* Divider con longitud ajustada */}
            <Divider
            sx={{
                borderColor: 'rgb(229, 60, 97)',
                borderWidth: 1.75,
                marginTop: '0.75%',
                marginBottom: '0.2%',
                width: '40%', // Aumentado para mayor longitud
            }}
            />
        </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
