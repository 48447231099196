import React from 'react';
import { Box, Container, Paper, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)({
  padding: '20px',
  borderRadius: '10px',
  borderColor: '#FFFFFF',
  borderWidth: '2px',
  borderStyle: 'solid',
  maxHeight: '80vh',
  overflowY: 'auto',
  boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff'
});

const StyledDivider = styled(Divider)({
  backgroundColor: '#F67147',
  height: '3px',
  margin: '20px 0'
});

const TermsAndConditions = () => {
  return (
    <Container sx={{ mt: 5, mb: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ color: '#000', fontWeight: 'bold' }}>
          Terms and Conditions
        </Typography>
      </Box>
      <StyledPaper>
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          1. Terms and Conditions
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            These Terms and Conditions govern the use of the SOUBID platform. When we refer to the platform, we mean www.soubid.com or the SOUBID mobile application, indistinctly (the "Platform").
            The use of the platform and its various resources implies acceptance of these Terms and Conditions. Therefore, before taking any action on or through the Platform, you must carefully read all the Terms and Conditions, and if you do not agree, we recommend that you refrain from continuing to use it.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          2. Description of the Platform
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            The Platform is a medium through which verification and qualification services for Users' academic and professional backgrounds are offered. Therefore, it is part of Soubid's essence to collect, display, and use personal data to achieve its objectives. Thus, by uploading a background to the Platform, the User consents to Soubid using it for the specified purposes. 
            In any case, the protection of personal data is our highest priority, and we are constantly adopting security measures to prevent attacks or inappropriate use by third parties.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          3. The SOUBID Model
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            SOUBID operates based on a scientific rating model. This model is the property of SOUBID, and is secret and confidential, with users having no right to access it. If any user disagrees with the confidentiality of the model, they are advised not to use the platform.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          4. Registered User Accounts
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            All actions on SOUBID require being registered on the Platform and having a valid Account. The User accepts that SOUBID may be unable to offer the Platform's services at all times.
            By registering as a Registered User on the Platform, you commit to providing us with truthful, correct, and up-to-date information; and you authorize us to remove it if it is proven to be false, incorrect, or inaccurate.
            When registering as a Registered User and verifying the Account, you must choose a secret access key, password, or passphrase, being solely responsible for maintaining its confidentiality, and therefore the information in your Account.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          5. Closure and Unverified Accounts
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            Registered Users may terminate this agreement with SOUBID at any time.
            In return, the User accepts and agrees that SOUBID may, with prior notice, limit, suspend, or terminate the service and Accounts, prohibit access to the Platform, its content, services, and tools, restrict or remove stored content, and take technical and legal actions to keep Registered Users out of the Platform if it deems that they are violating the Terms and Conditions.
            Furthermore, SOUBID may, at its own discretion, limit access, suspend, or delete Registered Users' Accounts for any of the following reasons, which are provided only as examples. If a User:
            Attempts to access the Platform or another Registered User's Account without authorization, or provides help or assistance to others to do so;
            Interferes with aspects of the Platform's security that remove, limit, or protect any type of content;
            Engages in conduct or acts that SOUBID considers fraudulent or malicious;
            Uses the Platform to promote illegal activities such as money laundering, terrorist financing, or other criminal activities;
            Breaches or contravenes the Terms and Conditions;
            Improperly pays for transactions made through the Platform;
            Causes operational difficulties on the Platform;
            If the Registered User's identity cannot be verified or any information provided by them is false or erroneous; or
            Upon request from a government agency or court of justice, if it complies with the respective legal and/or procedural norms that are applicable.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          6. Platform Availability
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            You accept and agree that SOUBID is not responsible for damages, harm, or losses to the Registered User caused by failures, interruptions, or errors in the system, server, Internet, or the Platform, except in cases of legal or regulatory non-compliance by SOUBID in this regard. SOUBID does not guarantee continuous or uninterrupted access and use of the Platform. The system may occasionally be unavailable due to technical difficulties or Internet failures, or for any other circumstance beyond SOUBID's control; in such cases, efforts will be made to restore it as quickly as possible, and contingency measures will be applied based on current regulations.
            SOUBID will make reasonable efforts to keep the Platform free of viruses, malware, or similar, however, you acknowledge and declare that access to the Platform is at your own risk and responsibility.
            Furthermore, you accept and take responsibility for damages and harm arising from your use of the Platform for purposes contrary to laws, good customs, public order, and current legislation, releasing SOUBID from any liability.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          7. Limited Right of Use
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            Unless otherwise stated, all material on this Platform is owned by SOUBID and is protected by copyright, trademark rights, and other applicable international laws. However, the user may review, share, print, and/or download the information from their Profile. The provisions in this clause do not apply to personal photographs of individuals, which are their property, or of those who have captured them, as the case may be.
            The SOUBID brand and logo used on the Platform ("Trademark") are the property of SOUBID and their respective owners. The software, texts, graphics, information, prices, exchanges, videos, and audios used on the Platform (the "Material") are also the property of SOUBID, unless expressly stated otherwise.
            The Trademark and Material must not be copied, reproduced, modified, republished, uploaded, posted, transmitted, scraped, collected, or distributed commercially, whether automated or manually. The use of any material on another platform or computer network, for any purpose other than that of the SOUBID Site, is strictly prohibited; any unauthorized use will constitute a violation of copyright, trademark rights, and other applicable international laws, and may result in civil or criminal penalties.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          8. Modifications to the Terms and Conditions
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            SOUBID reserves the right to modify these Terms and Conditions at any time. Users will be duly notified of any changes through their Account. Upon notification, it is the Users' responsibility to review the changes in the Terms and Conditions.
            If, after being notified of changes in the Terms and Conditions, Users continue to use and access the Platform, it will signify that they accept such changes.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          9. Severability
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            If any term or provision contained in these Terms and Conditions or its application to a person or circumstance is declared invalid or unenforceable, the remainder thereof, or the application thereof to any person or circumstance, other than those in respect of which it is held invalid, shall not be affected thereby and shall be valid and enforceable to the fullest extent permitted by Law, and SOUBID and You agree to replace that term or provision with other terms and provisions that, to the extent permitted by law, allow the parties to obtain the benefit of the term or provision thus held invalid or unenforceable.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          10. Contact
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
            If you have any questions, complaints, comments, or suggestions regarding these Terms and Conditions, your rights and obligations as a User arising from these, and/or the use of the Platform and its services or your User Account, we request that you contact us at support@soubid.com.
        </Typography>
      </StyledPaper>
    </Container>
  );
};

export default TermsAndConditions;
