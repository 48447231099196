import React from 'react';
import { Grid, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

function BackButton({ path, style, back }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (back) {
      return navigate(-1);
    } else {
      navigate(path);
    }
    localStorage.removeItem('simulatorPath');
  };

  return (
    <Grid item>
      <Button
        onClick={handleClick}
        startIcon={<ArrowBackIosNewIcon />}
        sx={{
          ...style, // <Trans>Aplica los estilos adicionales pasados como prop</Trans>
          textTransform: 'none',
          textDecoration: 'none',
          fontWeight: 'normal', // <Trans>Ajusta el peso de la fuente como '400' para no-bold</Trans>
          fontSize: '1.2rem', // <Trans>Ajusta el tamaño de la fuente a '1rem'</Trans>
        }}
      >
        Back
      </Button>
    </Grid>
  );
}

export default BackButton;
