import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SelectOptionMM({ options, onSelectionChange, name, width, selectedOption }) {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (options.length > 0) {
            const selectedValue = options.find(option => option.id === selectedOption) || null;
            setValue(selectedValue);
        }
    }, [selectedOption, options]);

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{
                width: width,
                padding: '8px 0',
                '& .MuiOutlinedInput-root': {
                    backgroundColor: 'transparent',
                    '& fieldset': {
                        borderColor: 'white',
                        borderRadius: '18px',
                    },
                    '&:hover fieldset': {
                        borderColor: 'white',
                        borderRadius: '18px',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#F67147',
                        borderRadius: '18px',
                    },
                    '& .MuiAutocomplete-input': {
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        color: 'white',
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        alignItems: 'center',
                    },
                },
                '& .MuiInputLabel-root': {
                    color: 'white',
                    fontSize: '0.8rem',
                },
                '& .MuiAutocomplete-popupIndicator': {
                    color: 'white',
                },
                '& .MuiAutocomplete-clearIndicator': {
                    color: 'white',
                },
            }}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onSelectionChange(newValue ? newValue.id : '');
            }}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={name}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        style: { color: 'white', fontSize: '0.8rem' },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            color: 'white',
                            paddingTop: '3px',  
                            paddingBottom: '3px',     
                        },
                    }}
                />
            )}
        />
    );
}

export default SelectOptionMM;
