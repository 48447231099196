import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, LinearProgress, useMediaQuery, Button, IconButton } from '@mui/material';
import BalanceIcon from '@mui/icons-material/Balance';
import RadarChart from './radarChart'; // Asegúrate de que el componente del gráfico esté implementado.
import theme from '../../styles/StyleTheme'; // Importa tu archivo de estilos.
import { useNavigate } from 'react-router-dom';
import buildingsBackground from '../../assets/images/fondo-edificiospng.png';
import { publicAxios } from '../../apiClients';
import BackButton from '../../components/BackButton';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';


const MarketFirm = () => {
    const navigate = useNavigate();
    const [firm, setFirm] = useState(null);

    // Uso de useMediaQuery para detectar pantallas pequeñas
    const isMobile = useMediaQuery('(max-width:600px)'); // Ajusta el breakpoint según necesites

    useEffect(() => {
        window.scrollTo(0, 0);
        const firmId = localStorage.getItem('firmId'); // Mover la obtención de firmId dentro del useEffect
        const fetchFirm = async () => {
            try {
                const response = await publicAxios.get(`/institution/market-monitor/${firmId}`);
                setFirm(response.data);
            } catch (error) {
                console.error('Error fetching firm data:', error);
            }
        };
        fetchFirm();
    }, []);

    const handleClickBalance = () => {
        navigate('/market-comparative');
    };

    // Estados para controlar la expansión de cada box
    const [isExpanded, setIsExpanded] = useState({
        reputation: false,
        revenue: false,
        trajectory: false,
        clients: false,
        individualPerformance: false,
        arena: false,
    });

    // Función para manejar el clic en "Ver más"
    const handleSeeMore = (key) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    // Textos para cada box
    const boxTexts = {
        reputation:
            "Considers firm's performance in major industry rankings, such as Chambers & Partners, The Legal 500, Who is Who Legal, among others.",
        revenue: "Based on the company's financial results.",
        trajectory: 'Recognizes the time that the firm has been operating in the market.',
        clients: 'Highlights the most relevant players that trust in the firm.',
        individualPerformance:
            "Considers the average of the firm's collaborators' ratings, based on background information verified by Soubid.",
        arena: 'Measures the market conditions in which the firm operates.',
    };

    // Datos para cada box
    const boxes = [
        { key: 'reputation', title: 'Reputation', value: firm?.reputation || 0, gridColumn: '3', gridRow: '2' },
        { key: 'revenue', title: 'Revenue', value: firm?.revenue || 0, gridColumn: '4', gridRow: '2' },
        { key: 'trajectory', title: 'Trajectory', value: firm?.trajectory || 0, gridColumn: '1', gridRow: '3' },
        { key: 'clients', title: 'Top Active Clients', value: firm?.clients || 0, gridColumn: '2', gridRow: '3' },
        { key: 'individualPerformance', title: 'Individual Performance', value: firm?.individualPerformance || 0, gridColumn: '3', gridRow: '3' },
        { key: 'arena', title: 'Arena', value: firm?.arena || 0, gridColumn: '4', gridRow: '3' },
    ];

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${buildingsBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    paddingX: isMobile ? '0%' : '5%',
                }}
            >
                <Grid container justifyContent="start" sx={{ mt: 1, ml: 0, mb: -3 }}>
                    <BackButton path={-1} />
                </Grid>
                <Box sx={{ p: 4 }}>
                    {/* Encabezado */}
                    <Box
                        sx={{
                            backgroundColor: '#03263D',
                            borderRadius: 2,
                            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
                            padding: 3,
                            marginBottom: 3,
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            {/* Columna 1: Nombre */}
                            <Grid item xs={12} sm={4}>
                                {firm && (
                                    <>
                                        <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>
                                            {firm.institutionName}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ color: 'white' }}>
                                            {firm.Country.name}
                                        </Typography>
                                    </>
                                )}
                            </Grid>

                            {/* Columna 2: Otros datos */}
                            <Grid
                                item
                                xs={12}
                                sm={5}
                                sx={{ mt: { xs: 2, sm: 0 }, textAlign: { xs: 'left', sm: 'right' } }}
                            >
                                <Typography variant="body1" sx={{ color: 'white' }}>
                                    Score:{' '}
                                    <span
                                        style={{
                                            color: theme.palette.primary.main,
                                            display: 'inline-block',
                                            minWidth: '50px',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {firm?.totalScore ? firm.totalScore.toFixed(2) : 'N/A'}
                                    </span>
                                </Typography>
                                <Typography variant="body1" sx={{ color: 'white' }}>
                                    Local Position:{' '}
                                    <span
                                        style={{
                                            color: theme.palette.primary.main,
                                            display: 'inline-block',
                                            minWidth: '50px',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {firm?.localPosition || 'N/A'}
                                    </span>
                                </Typography>
                                <Typography variant="body1" sx={{ color: 'white' }}>
                                    Global Position:{' '}
                                    <span
                                        style={{
                                            color: theme.palette.primary.main,
                                            display: 'inline-block',
                                            minWidth: '50px',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {firm?.globalPosition || 'N/A'}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ mt: { xs: 2, sm: 0 }, display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#3592D2',
                                        color: '#fff',
                                        '&:hover': { backgroundColor: '#2878B5' },
                                        paddingX: '5%',
                                        width: isMobile ? '100%' : '60%',
                                    }}
                                    onClick={() => navigate('/market-comparative')}
                                >
                                    Compare with other firm
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Grilla principal */}
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)',
                            gridTemplateRows: 'auto auto auto',
                        }}
                    >
                        {/* Gráfico que ocupa dos columnas y dos filas */}
                        <Grid
                            item
                            sx={{
                                gridColumn: isMobile ? '1' : '1 / span 2',
                                gridRow: isMobile ? '1' : '1 / span 2',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    backgroundColor: '#03263D',
                                    borderRadius: 2,
                                    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
                                    padding: isMobile ? '0px' : '20px',
                                    height: '100%',
                                    minHeight: '400px',
                                }}
                            >
                                <RadarChart firm={firm} /> {/* Componente del gráfico */}

                                {/* Botón con icono de balanza en la esquina inferior derecha */}
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        bottom: 16,
                                        right: 16,
                                        backgroundColor: '#75838E',
                                        color: theme.palette.white,
                                        borderRadius: '12px',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        width: 48,
                                        height: 48,
                                        '&:hover': {
                                            backgroundColor: '#5A6777',
                                        },
                                    }}
                                    onClick={handleClickBalance}
                                >
                                    <BalanceIcon />
                                </IconButton>
                            </Box>
                        </Grid>

                        {/* Boxes en la primera fila */}
                        <Grid item sx={{ gridColumn: isMobile ? '1' : '3', gridRow: isMobile ? 'auto' : '1' }}>
                            {/* Box de Partners */}
                            <Box
                                sx={{
                                    backgroundColor: '#03263D',
                                    padding: 2,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                    minHeight: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h3" sx={{ color: '#3592D2', fontSize: '3rem' }}>
                                    {firm?.partnersNumber || 'N/A'}
                                </Typography>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: 3, color: 'white' }}>
                                    Partners
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sx={{ gridColumn: isMobile ? '1' : '4', gridRow: isMobile ? 'auto' : '1' }}>
                            {/* Box de Other qualified members */}
                            <Box
                                sx={{
                                    backgroundColor: '#03263D',
                                    padding: 2,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                    minHeight: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h3" sx={{ color: '#3592D2', fontSize: '3rem' }}>
                                    {firm?.otherLawyersNumber || 'N/A'}
                                </Typography>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', marginTop: 0.5, color: 'white' }}>
                                    Other qualified
                                    <br />
                                    members
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Boxes con botón "Ver más" */}
                        {boxes.map((box) => (
                            <Grid
                                key={box.key}
                                item
                                sx={{
                                    gridColumn: isMobile ? '1' : box.gridColumn,
                                    gridRow: isMobile ? 'auto' : box.gridRow,
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#03263D',
                                        padding: 2,
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        minHeight: '190px',
                                        maxHeight: '190px',
                                        overflow: 'hidden',
                                        position: 'relative',
                                    }}
                                >
                                    {/* Contenido del box */}
                                    <Box sx={{ position: 'absolute', top: 10, left: 0, right: 0 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography
                                                variant="h7"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: isExpanded[box.key] ? '0.9rem' : '1rem',
                                                    transition: 'font-size 0.3s ease',
                                                    color: 'white',
                                                }}
                                            >
                                                {box.title}
                                            </Typography>
                                            {(box.key === 'revenue' && (
                                                firm?.revenuePro ? (
                                                    <CheckCircleIcon sx={{ color: theme.palette.primary.main, marginLeft: 0.5, fontSize: '1.3rem' }} />
                                                ) : (
                                                    <Tooltip title="This firm does not provide revenue information.">
                                                        <WarningIcon sx={{ color: theme.palette.primary.main, marginLeft: 0.5, fontSize: '1.2rem' }} />
                                                    </Tooltip>
                                                )
                                            ))}
                                            {box.key === 'clients' && (
                                                <Tooltip title={<Typography sx={{ textAlign: 'center', fontSize: '0.8rem' }}>This score is derived from the number of clients, as demonstrated by the relevant experiences reported by the firm's collaborators.</Typography>} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <InfoIcon sx={{ color: theme.palette.primary.main, marginLeft: 0.5, fontSize: '1.3rem' }} />
                                                </Tooltip>
                                            )}
                                        </Box>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: '#3592D2',
                                                marginY: 0.8,
                                                fontSize: isExpanded[box.key] ? '1.5rem' : '2rem',
                                                transition: 'font-size 0.3s ease',
                                            }}
                                        >
                                            {box.value || 'N/A'}
                                        </Typography>
                                        {!isExpanded[box.key] && (
                                            <Box sx={{ width: '70%', margin: '0 auto', marginTop: 1.5 }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={box.value || 0} // Default to 0 if box.value is undefined
                                                    sx={{
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: '#DBEAF6',
                                                        '& .MuiLinearProgress-bar': { backgroundColor: '#3592D2' },
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>

                                    {/* Botón "Ver más" */}
                                    <Button
                                        variant="text"
                                        sx={{
                                            position: 'absolute',
                                            bottom: isExpanded[box.key] ? '80px' : '10px',
                                            left: 0,
                                            right: 0,
                                            margin: '0 auto',
                                            textTransform: 'none',
                                            color: 'white',
                                            fontSize: '0.8rem',
                                        }}
                                        onClick={() => handleSeeMore(box.key)}
                                    >
                                        {isExpanded[box.key] ? 'See less' : 'See more'}
                                    </Button>

                                    {/* Texto adicional */}
                                    {isExpanded[box.key] && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                position: 'absolute',
                                                bottom: '10px',
                                                left: 10,
                                                right: 10,
                                                fontSize: '0.8rem',
                                                color: 'white',
                                            }}
                                        >
                                            {(box.key === 'revenue' ? (
                                                firm?.revenuePro ? (
                                                    'Based on the firm\'s actual results coming from public sources.'
                                                ) : (
                                                    'AI estimation based on public information.'
                                                )
                                            ) : (
                                                boxTexts[box.key]
                                            ))}
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Contacto */}
                <Box
                    sx={{
                        backgroundColor: 'none',
                        paddingTop: 4,
                        paddingBottom: 13,
                        paddingX: 8,
                        borderRadius: 'none',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ flexGrow: 1, textAlign: isMobile ? 'center' : 'left' }}>
                        <Typography variant="body1" sx={{ color: 'white', marginBottom: 1 }}>
                            Our AI model is fed with information coming from public sources or provided by the firms (in this case, verified by our team). Are you a member in this Firm and want to update displayed information?
                        </Typography>
                    </Box>
                    <Box sx={{ ml: isMobile ? 0 : 4, mt: isMobile ? 2 : 0 }}>
                        <Button
                            component="a"
                            href="https://thelegalindustry.com/contact-us/"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="contained"
                            sx={{
                                backgroundColor: '#f16548',
                                color: '#fff',
                                borderRadius: 4,
                                height: 30,
                                textTransform: 'none',
                                whiteSpace: 'nowrap',
                                '&:hover': { backgroundColor: '#e15538' },
                            }}
                        >
                            Contact us
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default MarketFirm;
