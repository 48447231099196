import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Divider, Box, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';

function ResponsiveFootBar() {
    const theme = useTheme();
    return (
        <Box component="footer" sx={{ bgcolor: 'black', py: 2 }}>
            <Container maxWidth="lg" align="center">
                <Typography variant="body2" color={theme.palette.gray3} align="center">
                    <Link to="/terms-and-conditions" style={{ color: theme.palette.gray3, textDecoration: 'none' }}>
                        Terms and Conditions
                    </Link>
                    {' | '}
                    <Link to="/market-monitor" style={{ color: theme.palette.gray3, textDecoration: 'none' }}>
                        Market Monitor
                    </Link>
                    {' | '}
                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.gray3, textDecoration: 'none' }}>
                        Privacy Policy
                    </a>
                </Typography>
                <Divider sx={{ bgcolor: theme.palette.gray3, my: 2, width: "80%" }} />
                <Typography variant="body2" color={theme.palette.gray3} align="center">
                    © {new Date().getFullYear()} Soubid — All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
}
export default ResponsiveFootBar;
