// BalanceRadarChart.js
import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
Chart,
PointElement,
LineElement,
Filler,
Tooltip,
Legend,
} from 'chart.js';

// Importar la escala radial personalizada
import CustomRadialScale from './customRadialScale';

// Registrar los componentes necesarios (excluyendo RadialLinearScale, ya registrado en CustomRadialScale)
Chart.register(PointElement, LineElement, Filler, Tooltip, Legend);

// Componente BalanceRadarChart
const BalanceRadarChart = ({ data, options }) => {
    return <Radar data={data} options={options} />;
};

export default BalanceRadarChart;
