import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactCountryFlag from 'react-country-flag';
import countryCodeMapping from '../../utils/CodeCountryMapping.json';
import Button from '@mui/material/Button';

const StyledTableRow = styled(TableRow)(() => ({ 
    backgroundColor: '#03263D',
    border: '1px solid white',
    borderRadius: '10px',
    marginBottom: '5px',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    color: 'white',
    position: 'relative',
}));

const LeftAlignTableCell = styled(TableCell)(({ isSmallScreen }) => ({
    fontWeight: 'bold',
    border: 'none',
    fontSize: '14px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    width: isSmallScreen ? '72px' : '190px',
    overflow: 'hidden',
}));

const CenterAlignTableCell = styled(TableCell)(({ isSmallScreen }) => ({
    fontWeight: 'bold',
    border: 'none',
    fontSize: '14px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: isSmallScreen ? '70px' : '160px',
}));

const NarrowTableCell = styled(TableCell)(({ isSmallScreen }) => ({
    fontWeight: 'bold',
    border: 'none',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    width: isSmallScreen ? '60px' : '100px',
    justifyContent: 'center',
    color: 'white',
}));

const StyledTableHeadRow = styled(TableRow)(({ isSmallScreen }) => ({ 
    backgroundColor: '#03263D',
    border: '1px solid white',
    borderRadius: '10px',
    marginBottom: '5px',
    paddingLeft: isSmallScreen ? '5px' : '10px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({  
    color: 'white',
    '&.Mui-active': {
        color: 'white',
    },
    '& .MuiTableSortLabel-icon': {
        color: 'white !important',
        marginLeft: '4px',
    },
}));

const OutsideTableCell = styled(TableCell)(({ isMediumScreen }) => ({
    position: 'absolute',
    right: '3%',
    top: '50%',
    transform: 'translateY(-50%)',
    fontWeight: 'bold',
    border: 'none',
    fontSize: '14px',
    display: 'flex-end',
    color: 'white',
    width: '100px',
}));

const FirmTable = ({ firms, sortBy, setSortBy }) => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const isMediumScreen = useMediaQuery('(max-width:1200px)');
    const isSpecificScreen = useMediaQuery('(max-width:1250px)');

    const handleSort = (field) => {
        setSortBy({
            field,
            direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
        });
    };

    const handleClick = (firmId) => {
        localStorage.setItem('firmId', firmId);
        navigate('/market-firm');
    };

    return (
        <TableContainer
            component={Paper}
            style={{
                border: 'none',
                boxShadow: 'none',
                backgroundColor: '#03263D',
                overflowX: isMediumScreen ? 'auto' : 'hidden',
                overflowY: 'hidden',
                width: '100%',
            }}
        >
            <Table style={{ width: '100%' }}>
                <TableHead>
                    <StyledTableHeadRow>
                        <NarrowTableCell isSmallScreen={isSmallScreen}>
                            <StyledTableSortLabel
                                active={sortBy.field === 'position'}
                                direction={sortBy.field === 'position' ? sortBy.direction : 'asc'}
                                onClick={() => handleSort('position')}
                            >
                                {isSmallScreen ? 'Pos' : 'Position'}
                            </StyledTableSortLabel>
                        </NarrowTableCell>

                        <LeftAlignTableCell isSmallScreen={isSmallScreen}>
                            <StyledTableSortLabel
                                active={sortBy.field === 'institutionName'}
                                direction={sortBy.field === 'institutionName' ? sortBy.direction : 'asc'}
                                onClick={() => handleSort('institutionName')}
                            >
                                Name
                            </StyledTableSortLabel>
                        </LeftAlignTableCell>

                        <CenterAlignTableCell isSmallScreen={isSmallScreen}>
                            <StyledTableSortLabel
                                active={sortBy.field === 'country'}
                                direction={sortBy.field === 'country' ? sortBy.direction : 'asc'}
                                onClick={() => handleSort('country')}
                            >
                                Country
                            </StyledTableSortLabel>
                        </CenterAlignTableCell>

                        {!isSmallScreen && (
                            <CenterAlignTableCell isSmallScreen={isSmallScreen}>
                                <StyledTableSortLabel
                                    active={sortBy.field === 'region'}
                                    direction={sortBy.field === 'region' ? sortBy.direction : 'asc'}
                                    onClick={() => handleSort('region')}
                                >
                                    Region
                                </StyledTableSortLabel>
                            </CenterAlignTableCell>
                        )}

                        <CenterAlignTableCell isSmallScreen={isSmallScreen}>
                            <StyledTableSortLabel
                                active={sortBy.field === 'totalScore'}
                                direction={sortBy.field === 'totalScore' ? sortBy.direction : 'asc'}
                                onClick={() => handleSort('totalScore')}
                            >
                                Total Score
                            </StyledTableSortLabel>
                        </CenterAlignTableCell>
                    </StyledTableHeadRow>
                </TableHead>
                <TableBody>
                    {firms.map((firm) => (
                        <StyledTableRow key={firm.id}>
                            <NarrowTableCell isSmallScreen={isSmallScreen}>
                                {firm.position}
                            </NarrowTableCell>
                            <LeftAlignTableCell isSmallScreen={isSmallScreen}>
                                {isSmallScreen ? (
                                    <div
                                        style={{
                                            maxWidth: '100%',
                                            overflowX: 'auto',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleClick(firm.id)}
                                    >
                                        {firm.institutionName}
                                    </div>
                                ) : (
                                    firm.institutionName
                                )}
                            </LeftAlignTableCell>

                            <CenterAlignTableCell isSmallScreen={isSmallScreen}>
                                <div
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        borderRadius: '50%',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <ReactCountryFlag
                                        countryCode={countryCodeMapping[firm.country.id] || 'US'}
                                        svg
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        title={firm.country.name}
                                    />
                                </div>
                            </CenterAlignTableCell>

                            {!isSmallScreen && (
                                <CenterAlignTableCell isSmallScreen={isSmallScreen}>
                                    {firm.country.region}
                                </CenterAlignTableCell>
                            )}

                            <CenterAlignTableCell isSmallScreen={isSmallScreen}>
                                {firm.totalScore ? firm.totalScore.toFixed(2) : 'N/A'}
                            </CenterAlignTableCell>

                            {!isSmallScreen && (
                                <OutsideTableCell> 
                                    <Button
                                        onClick={() => handleClick(firm.id)}
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            color: 'white',
                                            whiteSpace: 'nowrap',
                                            textDecoration: 'underline',
                                            textDecorationThickness: '2px',
                                            marginLeft: (isSpecificScreen || isMediumScreen) ? '50px' : '15px',
                                        }}
                                    >
                                        {(isSpecificScreen || isMediumScreen) ? 'Profile' : 'Firm Profile'}
                                    </Button>
                                </OutsideTableCell>
                            )}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FirmTable;
